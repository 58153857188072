import { LogoList } from 'ui/components/3-organisms/LogoList/LogoList';

export const LogoListFeature: React.FC<Content.LogoList> = ({ content }) => {
	return (
		<LogoList
			bgColor={content?.properties?.color}
			heading={content?.properties?.heading}
			logos={content?.properties?.logos.map((logo) => ({
				url: logo?.url,
				altText: logo?.properties?.altText,
			}))}
			trumpetHeading={content?.properties?.trumpetHeading}
		/>
	);
};
